/* CreditPoints.module.css */

.creditscontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.credits {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    margin: 10px;
}

.inputsection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    gap: 20px;
}

.btn {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background-color: #45a049;
}

.tablesection {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

/* Responsive design for small screens */
@media (max-width: 600px) {
    .creditscontainer {
        margin: 10px;
    }

    .credits {
        width: 100%;
        margin: 0;
    }

    .inputsection {
        margin: 10px;
    }
}
