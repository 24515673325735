/* Custom CSS for a Distinctive Ant Design Table */
/* You can modify these styles to match your design requirements */

/* Table container */
.ant-table-body {
  min-height: 267px;
}
.ant-table {
    font-size: 16px;
    border-radius: 4px;
    /* overflow: hidden; */
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Slightly elevated look */
  }
  
  /* Table header row */
  .ant-table-thead > tr > th {
    background-color: #1890ff; /* Bold blue header background */
    font-weight: bold;
    color: #fff;
    border: none; /* Remove header border */
    text-align: center;
    padding: 16px;
    border-top: 2px solid #1476c1; /* Top border for distinction */
  }
  
  /* Table body rows */
  .ant-table-tbody > tr > td {
    border: none; /* Remove cell borders */
    padding: 14px 16px;
  }
  
  /* Alternating row background colors */
  .ant-table-tbody > tr:nth-child(even) > td {
    background-color: #f7f7f7; /* Light gray for even rows */
  }
  
  /* Hover effect for rows */
  .ant-table-tbody > tr:hover > td {
    background-color: #e6f7ff; /* Light blue on hover */
  }
  
  /* Pagination styles */
  .ant-pagination {
    margin-top: 16px;
    text-align: right;
  }
  
  /* Customize pagination button styles */
  .ant-pagination-item-active {
    background-color: #1476c1; /* Highlight active page */
    border-color: #1476c1;
    color: #fff;
    transition: background-color 0.3s;
  }
  
  /* Customize pagination size changer styles */
  .ant-pagination-options-size-changer.ant-select-selector {
    margin-right: 16px;
  }
  
  /* Add a subtle hover effect to active pagination buttons */
  .ant-pagination-item-active:hover {
    background-color: #125eab;
  }
  
  /* Optional: Add a border to separate the table from other content */
  .ant-table {
    border: 1px solid #ddd;
  }
  
  /* Add more custom styles as needed */
  