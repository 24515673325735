table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: left;
  }
  
  thead th {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tbody tr:hover {
    background-color: #f2f2f2;
  }
  
  tbody td {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination .active {
    background-color: #0f7173;
    color: #fff;
  }