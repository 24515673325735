
  
  .container {
    text-align: center;
  }
  
  .drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;
    background-color: #f9f9f9;
    border-radius: 10px;
    width: 300px;
    margin: 0 auto;
  }
  
  .or {
    margin: 10px 0;
    font-weight: bold;
  }
  
  .file-label {
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
  }
  
  .file-input {
    display: none;
  }
  
  .file-info {
    margin-top: 20px;
    font-size: 14px;
    color: #333;
  }
  