.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px;
}
.container_trdate {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container_receiptfrom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px;
}
.tr_date {
  width: 120px;
}

.container_amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.voucherInput {
  padding: 12px;
  width: fit-content;
  margin: 0 0 12px 40px;
  display: flex;
  gap: 24px;
  align-items: center;
}

.voucherInput div button {
  margin-top: 30px;
}
