.treeView Tree{
    width: 100%;
    border: 1px solid  black;
    font-size: larger;
}
.drop{
    margin-left: 20%;
    width: 400px;
}
.tree{
 
    width: 70%;
    font-size: 18px;
}
/* .tree span:hover{
 transform: scale(1.2);
 transition: 1s;

} */