@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Whisper&display=swap");

:root {
  --bg-color: #ffffff;
  /* this is overall background color */

  --hover-color: #ffffff;
  /* this is used for input and button hover */

  --input-bg-color: #ffffff;

  --input-disabled-color:#ffffff;

  --focus-color: #ffffff;
  /* this is input focused color */

  --button-color: #ffffff;
  /* this is used in all buttons and footer of taskbar also */
  --btn-focus-color: #ffffff;
  --footer-bg-color: #ffffff;

  --btn-hover-color: #ffffff;
  /* button hover color */

  --text-color: #383838;
  --border-color: #383838;

  --taskbar-btn-color: white;
  /* taskbar enebled button color including taskbar info */

  --taskbar-disabled-btn-color: #ffffff;
  /* taskbar disabled btn color and also used in various backgrounds in theme */
  --collapse-box-bg-color: white;

  --collapse-header-bg-color: #ffffff;
  /* this is collapse header background color */

  --collapse-box-text-color: black;
  /* box text color */

  --maintab-bg-color: white;
  --taskbar-bg-color: white;
  --taskbar-text-color: black;
  --font-family: "Heebo", sans-serif;
}

html {
  background-color: var(--bg-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* background-color: #fff0ce; */
  color: var(--text-color);
  font-family: var(--font-family) !important;
}

input{
  background-color: var(--input-bg-color);
}

input:disabled {
  background-color: var(--input-disabled-color);
}

input:hover {
  background-color: var(--hover-color);
}

input:focus {
  background-color: var(--focus-color);
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-input{
  background-color: var(--input-bg-color);
}
.select__control:hover {
  background-color: var(--hover-color);
}

.btn {
  padding: 5px 20px;
  cursor: pointer;
  text-align: right;
  border: 1px solid var(--border-color);
  height: 37px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: var(--button-color);
}

.ant-btn {
  background-color: var(--button-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover{
  background-color: var(--btn-hover-color);
  transition: 0.1s;
  transform: scale(1.1);
}

.btn:hover {
  background-color: var(--btn-hover-color);
  transition: 0.1s;
  transform: scale(1.1);
}
.ant-btn:hover {
  background-color: var(--btn-hover-color);
  transition: 0.1s;
  transform: scale(1.1);
}

.btn:focus {
  background-color: var(--btn-focus-color);
}

.item-search-btn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  justify-self: center;
  gap: 4px;
  padding: 3px;
  width: 90px;
  border-radius: 8px;
  padding: 6px;
}

.ant-radio-button-wrapper-checked {
  background-color: var(--button-color) !important;
  border-color: var(--button-color) !important;
}

.ant-radio-button-wrapper:hover {
  background-color: var(--button-color) !important;
  border-color: var(--button-color) !important;
}

.taskbar_btn {
  background-color: var(--taskbar-btn-color);
}

.taskbar_btn:disabled {
  background-color: var(--taskbar-disabled-btn-color);
}

.taskbar {
  background-color: var(--taskbar-bg-color);
  --text-color: var(--taskbar-text-color);
}

.maintab {
  background-color: var(--maintab-bg-color);
}

.ant-collapse-content-box {
  background-color: var(--collapse-box-bg-color);
}

.ant-collapse-item {
  background-color: var(--collapse-header-bg-color);
}

.label.focused {
  background-color: var(--collapse-box-bg-color);
  color: var(--collapse-box-text-color);
}

.ant-modal .ant-modal-content {
  background-color: var(--collapse-box-bg-color);
}

.ant-modal .ant-modal-title {
  background-color: var(--collapse-box-bg-color);
}

.footer {
  background-color: var(--footer-bg-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--button-color) !important;
  border-color: var(--button-color) !important;
}

.tr001-purchaseOrder-itemsTable thead tr th,
.tr001-purchaseOrder-itemsTable tfoot tr td,
.tr012-salesOrder-itemsTable thead tr th,
.tr012-salesOrder-itemsTable tfoot tr td,
.tr013-items-grid thead tr th,
.tr013-items-grid thead tr td,
.tr013-items-grid tfoot tr td {
  background-color: var(--button-color) !important;
}

.tr013-items-grid .ant-table-summary tr td {
  /* amol-382 - changed the item grid background-color */
  background-color: var(--button-color) !important;
  border-radius: 0 !important;
  padding: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--button-color) !important;
  border-radius: 6px;
  /* Round the corners of the thumb */
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: var(--button-color);
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-table-wrapper .ant-table .ant-table-title+.ant-table-container table>thead>tr:first-child th:first-child,
:where(.css-dev-only-do-not-override-1adbn6x).ant-table-wrapper .ant-table .ant-table-title+.ant-table-container table>thead>tr:first-child th:last-child,
:where(.css-dev-only-do-not-override-1adbn6x).ant-table-wrapper .ant-table .ant-table-title+.ant-table-container table>thead>tr:first-child td:first-child,
:where(.css-dev-only-do-not-override-1adbn6x).ant-table-wrapper .ant-table .ant-table-title+.ant-table-container table>thead>tr:first-child td:last-child {
  background-color: var(--button-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-drawer .ant-drawer-body {
  background-color: var(--collapse-box-bg-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  background-color: var(--collapse-box-bg-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-tree.ant-tree-directory .ant-tree-treenode {
  background-color: var(--collapse-box-bg-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: var(--button-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-tree .ant-tree-switcher-leaf-line {
  background-color: var(--collapse-box-bg-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-tree-show-line .ant-tree-indent-unit {
  background-color: var(--collapse-box-bg-color);
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  background-color: var(--collapse-box-bg-color);
}

.over {
  position: sticky;
  top: 0;
  z-index: 2;
  border-radius: 6px;
  margin: 0px auto 24px;
  padding: 34px 0 0 0;
  background-color: var(--collapse-box-bg-color);
  text-align: center;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-pagination .ant-pagination-item-active{
  border-color: var(--button-color);
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-pagination .ant-pagination-item-active a{
  color: var(--button-color);
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-pagination .ant-pagination-item-active:hover{
  background-color: var(--button-color);
  border-color: var(--button-color);
}

.save-button{
  background-color:#4096FF;
}