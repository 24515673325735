/* //~! PLEASE DO NOT OVER-RIDE ANY CSS, ELSE YOU'LL BE FIRED :') */

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Margins as per ISO standards */



:root {
  --red: #f0a898;
  --green: #c7e4ab;
  --yellow: #fff4a1;
  --blue: #a3cdef;
  /* amol-wms - added new color */
  --grey: #d3d3d3;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: whitesmoke;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

/* Custom Component CSS and Purchase order CSS */

input[type="file"] {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
}

.logMainFrame {
  width: fit-content;
  margin: auto;
}

.searchBox {
  padding: 8px;
  border-radius: 6px;
  margin: auto;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}
.additionalDetails {
  padding: 8px;
  border-radius: 6px;
  margin: auto;
  /* amol-temp - width change to 100% from fit-content */
  width: 100%;
  display: flex;

  gap: 24px;
  flex-wrap: wrap;
}

.itemsDetails {
  padding: 8px;
  border-radius: 6px;
  margin: auto;
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}



.under {
  overflow-y: scroll;
  max-height: 66vh;
}

.inputLabels {
  font-size: 20px;
  text-align: start;
}

.inputBox,
.mobileInput,
.fileUpload {
  display: flex;
  /* gap: 12px; */
  /* margin: auto; */
  height: 36px;
  border: 0;
  width: 100%;
  border-radius: 8px;
  padding: 6px 10px;
  font-size: 16px;
  outline: 1px solid #cccccc;
}

.fileUpload {
  width: fit-content;
  display: flex;
  align-items: center;
}

.btnBox {
  display: flex;
  gap: 12px;
}

.logBox {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* border-radius: 12px; */
  /* margin: 24px auto 0; */
  padding: 24px;
  display: flex;
  width: fit-content;
  gap: 30px;

  /* border: "border: 5px solid red"; */
}

.completed {
  background-color: var(--green);
}

.ordered {
  background-color: var(--yellow);
}

.pending {
  background-color: var(--red);
}

.partyBox {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 10vw;
  padding: 12px;
}

.partyBox > p {
  font-size: 20px;
}

.supplierBox {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 200px;
  margin-right: 42px;
  word-wrap: break-word;
}

.supplierBox > p {
  font-size: 24px;
}

.party-name {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.partyDetails {
  display: flex;
  gap: 50px;
}

.partyDetails > div:last-child {
  width: 160px;
}

.numeric {
  text-align: start;
}

.logTableVisible {
  display: block;
  width: fit-content;
  margin: 12px 0;
}

.logTableHidden {
  display: none;
  width: fit-content;
  margin: 12px 0;
}

.noDataFound {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noDataFound > img {
  height: 500px;
  width: 500px;
}

/* Login Box CSS */

/* TR001 : Purchase Order */
.tr001Input {
  height: 24px;
  padding: 4px 4px 4px 8px;
  border: 1px solid black;
  border-radius: 4px;
  width: 100%;
  text-align: right;
}
/* WMS */
.WMSInput {
  height: 24px;
  padding: 4px 4px 4px 8px;
  border: 1px solid black;
  border-radius: 4px;
  width: 100%;
  text-align: left;
}

.tr001MiddleBox {
  margin: 10px 0 10px 0;
}

/* TR031: Stock Verification */

.tr031MainFrame {
  border: 1px solid;
  width: 95vw;
  margin: auto;
}

.tr001DisplayRecord {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: ghostwhite;
  font-size: 18px;
  margin-bottom: 12px;
}

/*//~# Print document common styles */

.printPortrait {
  height: auto;
  width: 700px;
  border: 1px solid black;
  margin: auto auto;
  font-size: 10pt;
}

.printLandscape {
  height: auto;
  width: 842px;
  border: 1px solid black;
  margin: auto auto;
  font-size: 10pt;
}

.headerTable,
.footerGstTable,
.commonTable {
  border-collapse: collapse;
  /* table-layout: fixed; */
  width: 100%;
}

.headerTable td,
.footerGstTable td,
.commonTable td {
  border: 1px solid black;
  border-top: unset;
  padding: 2px 4px 2px 2px;
  overflow: hidden;
}

/* .headerTable tr:last-child > td {
  border-bottom: unset;
} */

.headerTable td:nth-child(odd) {
  border-left: unset;
}

.headerTable td:nth-child(even) {
  border-right: unset;
}

.commonTable th {
  border: 1px solid;
  border-top: unset;
  font-weight: 400;
}

.commonTable > tr > th:first-child {
  border-left: 0;
}

.headerLeftBox,
.headerBoxRight {
  flex: 1;
  /* border: 1px solid black; */
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headerLeftBox {
  border-right: 1px solid black;
}

.headerBoxRight {
  border-left: 1px solid black;
}

.headerLeftBox p:first-child,
.headerBoxRight p:first-child {
  font-weight: 700;
}

.logoBox {
  height: 64px;
  width: 64px;
  /* border: 1px solid; */
}

.logoBox img {
  height: 100%;
  width: 100%;
}

.signatureBox {
  display: flex;
}

.signatureBox > div {
  border-right: 1px solid black;
  width: 50%;
  padding: 72px 12px 4px 12px;
  display: flex;
  justify-content: space-between;
}

.signatureBox > div:last-child {
  position: relative;
}

.signatureBox > div:last-child div {
  /* border: 1px solid red; */
  text-align: center;
  position: absolute;
  bottom: 4%;
  right: 6%;
}

.signatureBox > div:last-child div p {
  line-height: 16px;
}

.signatureBox div:last-child {
  border-right: unset;
}

.signatureBox > div > p {
  align-self: flex-end;
}

/* The below display flex property adds a space after the child div elements for some un-known reason, commenting the display prop */
.footerGstRowsBox {
  border: 0px solid black;
  /* display: flex; */
}

/* .footerGstRowsBox > div:nth-child(2) {
  border: 1px solid red;
  border-bottom: 1px solid;
  width: 40%;
}

.footerGstRows {
  display: flex;
  flex-direction: column;
  width: 60%;
} */

.amountPayable > tbody > tr > td {
  border-right: unset;
  /* border-left: 0; */
  font-size: 8pt;
  page-break-inside: avoid;
  page-break-after: avoid;
}

.amountPayable > tbody > tr:last-child > td {
  font-weight: 700;
  font-size: 14px;
}

.gstRows {
  /* border: 1px solid black; */
  width: 100%;
  border-collapse: collapse;
}

.gstRows td {
  border: 1px solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 2px 4px 2px 2px;
}

.printBody {
  border-bottom: 1px solid black;
}

.printBody .ant-table-row {
  font-size: 10pt;
  font-weight: 400;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.printBody .ant-table-cell {
  border: 1px solid;
  border-right: unset;
  padding: 2px 2px !important;
  font-size: 8pt;
  background-color: white;
  border-collapse: collapse;
}

.printBody .ant-table-thead > tr > th {
  font-weight: 400;
  background-color: white;
  border: 1px solid black;
  border-top: 1px solid;
  border-right: unset;
  border-top: unset;
  border-radius: 0px !important;
}

.printBody .ant-table-thead > tr > th:first-child,
.ant-table-tbody > tr > td:first-child {
  border-left: 0px;
}

.printBody .ant-table-summary td {
  font-size: 8pt;
}

.printBody .ant-table-summary td {
  text-align: right;
}

.printBody .ant-table-summary td:first-child {
  text-align: left;
  border-left: unset;
}

@page {
  margin: 0.5in 0.1in 0.5in 0.25in;
}

/* New CSS for table, using prev v1 styles also, A4 Size: 297mm  */

.report-container {
  border-collapse: collapse;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /* height: 297mm; */
  height: auto;
  width: 100%;
  margin: auto;
}

.report-container thead .header-info {
  /* border: 1px solid red; */
  /* prev height: 250px; additional space in printing for no reason! */
  height: auto;
}

.report-container tbody .main {
  /* prev height: 762px - breaking the next page css, overlapping */
  height: auto;
  /* border: 1px solid; */
  margin: 0;
  width: auto;
  /* break-after: always; */
}

.report-container tfoot .footer-info {
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid black;
}

@media print {
  thead .report-header {
    display: table-header-group;
  }

  tfoot .report-footer {
    display: table-footer-group;
  }
}

/* V2 Document printer CSS */
.documentDetails {
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
}

.documentDetails div {
  /* border: 1px solid red; */
  flex: 1;
}

.documentDetails div table {
  /* border: 1px solid; */
  border-collapse: collapse;
  height: 100%;
  width: 100%;
  font-size: 8pt;
}

.documentDetails div table td {
  border: 1px solid black;
  padding: 2px;
}

/* Truncating all the table cells and keeping max */

.documentDetails div table tr td {
  /* border: 1px solid black; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentDetails div table tr td:nth-child(2) {
  border-right: unset;
}

.documentDetails div:first-child table tr td:first-child {
  border-left: unset;
}

.partyDetailsv2,
.selfDetailsv2 {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 4px;
  text-align: center;
}

.partyDetailsv2 p,
.selfDetailsv2 p {
  font-size: 8pt;
}

.partyDetailsv2 {
  text-align: start;
}

/* Transaction styles */

.tr023-table {
  border: 1px solid rgb(120, 120, 120);
  border-collapse: collapse;
}

.tr023-table tr td {
  border: 1px solid rgb(120, 120, 120);
  padding: 12px 8px;
}

.tr023-pending-instrument,
.tr023-account-list,
.tr023-last-table {
  border: 1px solid rgb(120, 120, 120);
  border-collapse: collapse;
  margin: 24px 0 0 0;
}

.tr023-pending-instrument tr td,
.tr023-account-list tr td,
.tr023-last-table tr td {
  border: 1px solid rgb(120, 120, 120);
  padding: 8px;
}

/* TR001 - Purchase Order CSS */

.tr001-purchaseOrder-itemsTable thead tr th,
.tr001-purchaseOrder-itemsTable tfoot tr td,
.tr012-salesOrder-itemsTable thead tr th,
.tr012-salesOrder-itemsTable tfoot tr td,
.tr013-items-grid thead tr th,
.tr013-items-grid thead tr td,
.tr013-items-grid tfoot tr td {
  /* amol-wms - changed the item table grid header footer color */
  background-color: var(--grey) !important;
  border-radius: 0 !important;
}

.tr013-items-grid .ant-table-thead tr th {
  padding: 6px;
}

.tr013-items-grid .ant-table-summary tr td {
  /* amol-382 - changed the item grid background-color */
  background-color: var(--grey) !important;
  border-radius: 0 !important;
  padding: 6px;
}

/* login*/
.maindiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e5e3e3;
}

.main-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 65%; /* Make sure the content takes up the entire width of maindiv */
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 10px;
  border-radius: 10px;
}

.imformation,
.loginBox {
  width: 45%; /* Adjust the width of both boxes as per your preference */
  padding: 20px;
}

.imformation img {
  max-width: 100%;
  height: auto;
}

.loginBox {
  height: auto; /* Remove the fixed height to allow it to expand as needed */
}

/* Add some spacing between the two boxes */
.imformation + .loginBox {
  margin-left: 20px;
}
/* Style for login form */
.loginBox {
  width: 45%; /* Adjust the width as needed */
  padding: 20px;
  background-color: #fff;
  height: auto;
  text-align: center;
}

/* Style for form title */
.loginBox h3 {
  font-size: 27px;
  color: #333;
  margin-bottom: 20px;
}

/* Style for input labels */
.loginBox p {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
  text-align: left;
}

/* Style for input fields */
.loginBox input[type="text"],
.loginBox input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 1.5;
}

/* Style for "Forgot Password?" link */
.loginBox p.forgot-link {
  font-size: 14px;
  color: #007bff; /* Change the color as needed */
  text-decoration: underline;
  cursor: pointer;
}

/* Style for the submit button */
.loginBox button {
  width: 100%;
  padding: 10px;
  background-color: #007bff; /* Change the color as needed */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.loginBox button:hover {
  background-color: #0056b3; /* Change the hover color as needed */
}
.avatar:hover {
  transform: scale(
    1.1
  ); /* Increase the scale on hover (e.g., 110% of the original size) */
}

/* You can add more specific styles to your InputComponent and Button components here

https://bizzcontrol.in/demo/ 
 */

.Pickfromdcsi:hover {
  transform: scale(1.25);
  transition: 1s;
  color: #333;
}

.Searchbillsi:hover {
  transform: scale(1.25);
  transition: 1s;
  color: #333;
}

.pickfromso:hover {
  transform: scale(1.25);
  transition: 1s;
  color: #333;
}

.addmssg:hover {
  transform: scale(1.25);
  transition: 1s;
  color: #333;
}
/* saveproceed */
.saveproceed:hover {
  transform: scale(1.12);
  transition: .5s;
  color: #333;
}