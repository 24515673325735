.printTable .ant-table-tbody {
  border: 1px solid black;
  border-collapse: collapse;
}
.printTable {
  border: 1px solid black;
  border-collapse: collapse;

  border-left: 1px solid black;
  border-right: 1px solid black;
}
.printTable .ant-table-cell {
  border: 1px solid;
  border-right: unset;
  padding: 2px 2px !important;
  font-size: 8pt;
}

.printTable .ant-table-thead > tr > th {
  font-weight: 400;
  background-color: white;
  border: 1px solid black;
  border-right: unset;
  border-top: unset;
  border-radius: 0px !important;
}
.printTable.ant-table-thead {
  align-items: center;
}

.printTable .ant-table-thead > tr > th:first-child,
.ant-table-tbody > tr > td:first-child {
  border-left: 0px;
}

.printTable .ant-table-summary td {
  font-size: 10pt;
}

.printTable .ant-table-summary td {
  text-align: right;
}

.printTable .ant-table-summary td:first-child {
  text-align: left;
  border-left: unset;
}

/* let make with smaller- */
.print .ant-table-tbody {
  font-size: 12pt;
  border-collapse: collapse;
}
.print .ant-table-cell {
  padding: 3px 3px !important;
  font-size: 10pt;
  height: 25px;
}
.print .ant-table-thead > tr > th {
  font-weight: 800;
  background-color: white;
  width: fit-content;
  border-radius: 0px !important;
}

table > head {
  margin-left: 2px;
}

.printtotalline .ant-table-tbody > tr:last-child {
  font-weight: 900;
  background-color: rgb(224, 222, 248);
}
/* let make with smaller- */
.printtotalline .ant-table-tbody {
  font-size: 12pt;
  border-collapse: collapse;
}
.printtotalline .ant-table-cell {
  padding: 3px 3px !important;
  font-size: 10pt;
  height: 25px;
}
.printtotalline .ant-table-thead > tr > th {
  font-weight: 800;
  background-color: white;
  width: fit-content;
  border-radius: 0px !important;
}

/* //~# DATA Table ant-d CSS over-ride */

.dataTable {
  border: 1px solid black;
}

.dataTable .ant-table-row {
  font-size: 9pt;
  font-weight: 400;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.dataTable .ant-table-cell {
  border: 1px solid;
  border-bottom: 1px solid black !important;
  border-right: unset;
  padding: 2px 2px !important;
  font-size: 9pt;
  background-color: white;
  border-collapse: collapse;
}

.dataTable .ant-table-thead tr th {
  font-weight: 400;
  background-color: white;
  border: 1px solid black;
  border-top: 1px solid;
  border-right: unset;
  border-top: unset;
  border-radius: 0px !important;
}

.dataTable .ant-table-thead tr th:nth-child(1) {
  border-left: 0;
}

.headerDetails {
  border: 1px solid black;
  /* border-bottom: unset; */
  display: flex;
  align-items: center;
  gap: 48px;
  padding: 4px 8px 8px 4px;
}

.reportHeading {
  border: 1px solid black;
  border-bottom: unset;
  padding: 2px 2px 2px 4px;
}
