body {
  z-index: -1;
  /* visibility: hidden; */
}
.gridhelp {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* width:80%; */
  grid-gap: 30px;
}
.gridhelp > a {
  grid-gap: 50px;
}
.buttons {
  /* border: 1px solid red; */
  position:fixed;
  width:70%;
  gap:15px;
  /* margin-bottom: 12%; */
  background-color: white;
}

.buttons>Button{
    /* border: 1px solid red; */
    margin-left:10px
}
.helpOptions{
    /* border: 1px solid green; */
    margin-bottom: 1%;
}
.searchbox{
    /* border:1px solid blue; */
    margin-top: 2%;
    margin-bottom:2%;
    width:40%;
    display: flex;
    align-items: center;
}
.searchbox input{
  /* border:1px solid blue; */
  height: 30px;
  padding: 8px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;

  outline: "none";
  width: "100%";
  box-sizing: "border-box";
 
}
.searchbox *{
    margin-left:5px;
    margin-top:7%;
}
.helppage{
  /* border:1px solid red; */
  overflow: scroll;  
  height:450px; 
  margin:auto;
  width:98%;
}
.forprint{
  overflow: scroll;
  /* border:1px solid green; */
  height: 350px;
  width: 1100px;
  margin: auto;
  margin-bottom: 5%;
  visibility: visible;
} 
.highlight {
  background-color: yellow;
}
.highlights{
  background-color: white;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  width: 70%;
  height: 70%;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}

.tooltip {
  position: relative;
}

.tooltip::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 4px;
  font-size: 14px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}

.tooltip:hover::after {
  opacity: 1;
}

.close-button {
  /* position: absolute; */
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  margin:'right';
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #f2f2f2;
}

.close-button svg {
  color: #888;
}
