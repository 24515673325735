.scantable {
  width: 70%;
  font-size: "12px";
}
.not-scantable {
  width: 100%;
}

.itemsMainBox {
  box-sizing: border-box;
  padding: 4px;
  margin: 12px auto 12px;
  width: 100%;
  height: 100%;
}

.singleItemData thead tr th,
.singleItemData tbody tr td {
  text-align: right;
}

.singleItemData thead tr th:first-child,
.singleItemData tbody tr td:first-child {
  width: 55%;
  text-align: left;
}

.barcodeTable thead tr th:last-child {
  width: 10%;
  text-align: center;
}

.barcodeTableWrapper {
  overflow: auto;
  height: 530px;
  margin: 24px auto;
}

.barcodeTableWrapper table thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

.barcodeTable thead tr th,
.barcodeTable tbody tr td {
  /* border: 1px solid; */
  padding: 6px;
}

.barcodeTable tbody tr td:last-child {
  padding-left: 18px;
}
