.etltool_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
}
.etltool_back {
  display: flex;
  align-items: flex-start;
  width: 98vw;
  margin-top: 1vw;
}
.etltool_fields {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  gap: 2vw;
  margin-top: 3vw;
}

.etltool_file {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  width: auto;
}
.tabledata {
  margin-top: 3vw;
  margin-left: 20vw;
}
