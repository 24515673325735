.divTr{
   
   margin-top: 10px;
   margin-bottom: 10px;
   padding: 30px;
   /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}
.customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 70%;
    border-radius: 10px;
  }
  
  .customers td, .customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* .customers tr:nth-child(even){background-color: #f2f2f2;} */
  
  .customers tr:hover {background-color: #ddd;}
  
  .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color:  #c1c5c4;
    color: white;
  }