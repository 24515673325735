.boxDomainmain{
    
    width: 60%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:auto;
    margin-top: 20px;
}
.boxDomainmain>div{
   /* border:1px solid red; */
   margin-top:10px;
}
.locationDiv{
    display: flex;
    text-align: center;
    justify-content: space-between;
   background-color:#c7ebcd;
   gap: 10px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    margin-bottom: 5px;
    height: 50px;
    padding: 20px;
    border-radius: 5px;
    color: black;

}
.whiteDiv{
    display: flex;
    text-align: center;
    justify-content: space-between;
   background-color: white;
   gap: 10px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    margin-bottom: 5px;
    height: 50px;
    padding: 20px;
    border-radius: 5px;

}
.locationTitle{

  margin-top: 20px;
   
    color: grey;
    overflow-y: scroll;
   margin-bottom: 10px;
}
.btnD{
    width: 100px;
 border-radius: 5px;
    height: 35px;
    margin-left: 5px;
  background-color: white;
  border: none;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.btnsave{
  display: flex;
gap:100px;
justify-content:center;
margin-top:20px

}
.drop{
    margin-left: 20%;
    width: 400px;
}
.InputCustome{
    height: 40px;
    width: 400px;
    margin-left:2px;
    border-radius: 5px;
   border: none;
   box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

}
.incheck{
  
   border-radius: 10px;
   border: none;
    height: 20px;
    width: 30px;
    padding: 5px;
    margin-left: 20px;
    

}
.incheck::after{
 background-color: green;
}
.divCard{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: auto;
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    padding-bottom: 40px;
    margin-bottom: 50px;
}
.searchbtn{
    border: none;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
.contentDiv{
    overflow-y: scroll;
    height: 260px;
    padding-top: 20px;
}