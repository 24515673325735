

.mainFrame {
  width: 90vw;
  margin: auto;
  padding: 4px;
}

.sv004Inputs {
  display: flex;
  gap: 24px;
  align-items: center;
}

.sv004Inputs p {
  margin-bottom: 6px;
}

.sv004Table {
  margin: 24px 0 0;
}

.controls {
  border: 1px soid black;
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px 0;
}

.surveyCtrl {
  display: flex;
  gap: 12px;
}
