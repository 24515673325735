.rectangle-box {

    display: flex;

    justify-content: center;

    align-items: center;

    width: 100%;

    height: 100%;

    font-size: 14px;

    font-weight: bold;

    background-color: #ccebff;

    color: white;

  }

  

  .rectangle-box h3 {

    margin: 0;

    padding: 8px;

  }

  

  .rectangle-box div {

    padding: 8px;

  }

  

  .MuiDialog-paper {

    padding: 16px;

    width: 300px;

  }

  

  .MuiDialogTitle-root {

    padding-bottom: 0;

  }

  

  .MuiDialogContent-root {

    padding-top: 8px;

  }