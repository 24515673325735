.filter-section {
    margin-bottom: 20px;
   
  }
  
  .info-section, .table-section {
    margin-bottom: 40px;
    
  }
  
  h2 {
    color: #333;
  }
  
  p {
    color: #666;
  }
  
  .data-table {
  
    border-collapse: collapse;
  
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  td {
    &:hover {
      background-color: #f5f5f5;
    }
  }
  
  [data-editable="true"]:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    outline: 1px solid #ddd;
  }
  .info-section, .table-section {
    margin-bottom: 40px;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .expanded {
    max-height: 500px; /* Set a suitable maximum height for expansion */
  }
  
  h2 {
    color: #333;
    cursor: pointer;
  }
  
  .info-container {
    display: flex;
    flex-direction: row; /* Make it flex in the x-axis */
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-color: rgb(205, 238, 189);
    border-radius: 10px;
  }
  
  .info-item {
    display: flex;
    flex-direction: column;
    margin-right: 10px; /* Add some margin between items if needed */
  }
  
  /* Add any additional styling as needed */
  
  
  /* Add any additional styling as needed */
  