.barcode-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .barcode-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(33.33% - 20px); /* Adjust the width as needed */
    margin-bottom: 20px;
  }