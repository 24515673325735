@import url('https://fonts.googleapis.com/css2?family=BenchNine&display=swap');

.submitBtn {
    border: none;
    padding: 4px 26px;
    background-color: #1A8BE5;
    color: white;
    font-family: 'BenchNine';
    font-size: 14px;
    font-weight: 550;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    letter-spacing: .5px;

}

.submitBtn:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border-top: 2px solid #1A8BE5;
    border-left: 2px solid #1A8BE5;
    transition: all 0.25s;
}

.submitBtn:hover:before,
.submitBtn:hover:after {
    height: 100%;
    width: 100%;
}

.submitBtn:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-radius: 2px;
    border-bottom: 2px solid #1A8BE5;
    border-right: 2px solid #1A8BE5;
    transition: all 0.25s;
}

/*Add Btn */
.addBtn {
    border: none;
    padding: 4px 26px;
    background-color: #e31c79;
    color: white;
    font-family: 'BenchNine';
    font-size: 14px;
    font-weight: 550;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    letter-spacing: .5px;
}

.addBtn:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border-top: 2px solid #e31c79;
    border-left: 2px solid #e31c79;
    transition: all 0.25s;
}

.addBtn:hover:before,
.addBtn:hover:after {
    height: 100%;
    width: 100%;
}

.addBtn:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-radius: 2px;
    border-bottom: 2px solid #e31c79;
    border-right: 2px solid #e31c79;
    transition: all 0.25s;
}

/* View Button*/
.viewBtn {
    border: none;
    padding: 4px 26px;
    background-color: #1db806;
    color: white;
    font-family: 'BenchNine';
    font-size: 14px;
    font-weight: 550;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    letter-spacing: .5px;
}

.viewBtn:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border-top: 2px solid #1db806;
    border-left: 2px solid #1db806;
    transition: all 0.25s;
}

.viewBtn:hover:before,
.viewBtn:hover:after {
    height: 100%;
    width: 100%;
}

.viewBtn:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-radius: 2px;
    border-bottom: 2px solid #1db806;
    border-right: 2px solid #1db806;
    transition: all 0.25s;
}

/* Back Button*/
.backBtn {
    border: none;
    padding: 4px 26px;
    background-color: #1381a3;
    color: white;
    font-family: 'BenchNine';
    font-size: 14px;
    font-weight: 550;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    letter-spacing: .5px;
}

.backBtn:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border-top: 2px solid #1381a3;
    border-left: 2px solid #1381a3;
    transition: all 0.25s;
}

.backBtn:hover:before,
.backBtn:hover:after {
    height: 100%;
    width: 100%;
}

.backBtn:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-radius: 2px;
    border-bottom: 2px solid #1381a3;
    border-right: 2px solid #1381a3;
    transition: all 0.25s;
}

/* New Button*/
.saveBtn {
    border: none;
    padding: 4px 26px;
    background-color: #cb3464;
    color: white;
    font-family: 'BenchNine';
    font-size: 14px;
    font-weight: 550;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    letter-spacing: .5px;
}

.saveBtn:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border-top: 2px solid #5f6ba0;
    border-left: 2px solid #5f6ba0;
    transition: all 0.25s;
}

.saveBtn:hover:before,
.saveBtn:hover:after {
    height: 100%;
    width: 100%;
}

.saveBtn:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-radius: 2px;
    border-bottom: 2px solid #919fdd;
    border-right: 2px solid #5f6ba0;
    transition: all 0.25s;
}

/* Save Button*/
.newBtn {
    border: none;
    padding: 4px 26px;
    background-color: #cb3464;
    color: white;
    font-family: 'BenchNine';
    font-size: 14px;
    font-weight: 550;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    letter-spacing: .5px;
}

.newBtn:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    top: -5px;
    left: -5px;
    border-radius: 2px;
    border-top: 2px solid #cb3464;
    border-left: 2px solid #cb3464;
    transition: all 0.25s;
}

.newBtn:hover:before,
.newBtn:hover:after {
    height: 100%;
    width: 100%;
}

.newBtn:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -5px;
    right: -5px;
    border-radius: 2px;
    border-bottom: 2px solid #919fdd;
    border-right: 2px solid #cb3464;
    transition: all 0.25s;
}