.contraWrapper {
  padding: 10px;
  border-radius: 8px;
  margin: auto;
  width: max-content;
}

.topFilters {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 36px;
  margin-top: 24px;
}

.dropdownBox {
  padding: 10px 0;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: space-between;
}

.amount {
  display: flex;
  gap: 4px;
  width: 250px;
  height: fit-content;
}
