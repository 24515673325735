/* .App {
  position: relative;
 
 
 
}
 
 
 
.rectangle-box {
  width: 100px;
  height: 50px;
  background-color: white;
  position: absolute;
  border: 2px solid black;
} */
 
 
 
/* App.css */
.AddBox {
  display: flex;
  flex-wrap: wrap;
}

.rectangle-box {
  position: absolute;
  border: 2px solid black;
  background-color: #f0f0f0;
  cursor: move;
  overflow: hidden;
  resize: both;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  padding: 8px;
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 2px solid black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  cursor: se-resize;
}

  .resize-handle.bottom-right {
    bottom: 0;
    right: 0;
  }


  /* setBoxes((prevBoxes) => {
    const updatedBoxes = [...prevBoxes]
    console.log("previous value",)
    updatedBoxes[selectedBox].caption = caption;
    updatedBoxes[selectedBox].fontsize = fontsize;
    updatedBoxes[selectedBox].fontname = fontname;
    updatedBoxes[selectedBox].printbox = printbox;
    updatedBoxes[selectedBox].pageno = pageno;
    updatedBoxes[selectedBox].fontitalic = fontitalic;
    updatedBoxes[selectedBox].fontbold = fontbold;
    updatedBoxes[selectedBox].fieldtype = fieldtype;

    return updatedBoxes;
  }); */
