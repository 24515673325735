.tablesection {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

/* Responsive design for small screens */
@media (max-width: 600px) {
    .creditscontainer {
        margin: 10px;
    }

    .credits {
        width: 100%;
        margin: 0;
    }

    .inputsection {
        margin: 10px;
    }
}
