CSS

.custom-table .ant-table {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  width: 1099px;
  word-wrap: break-word;
  overflow-x: hidden; 
  }
.custom-table .ant-table-cell {
    
  border: 1px solid black;
  background-color: white;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  word-wrap: break-word;
  overflow-x: hidden; 
  }

  .custom-table .ant-table-thead > tr > th {
    
    border: 1px solid black;
    background-color: white;
    padding: 1px;
  font-size: 13px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  word-wrap: break-word;
    }

  
  .custom-table .ant-table-tbody > tr > td {
    
  border: 1px solid black;
  background-color: white;
  padding: 1px;
  font-size: 13px;
  overflow-x: hidden; 
  word-wrap: break-word;
  }

  .custom-table .ant-table-thead > {
    background-color: white; /* Set even rows to white */
    border-radius: 0px;
  }

  .custom-table .ant-table-tbody > tr:nth-child(even) {
    background-color: white; /* Set even rows to white */
  }
  
  .custom-table .ant-table-tbody > tr:nth-child(odd) {
    background-color: white; /* Set odd rows to white */
  }
  
  .custom-table .ant-table-tbody > tr {
   
    border: 1px solid black;
  background-color: white;
  word-wrap: break-word;
  }




  .simple-table {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    width: 1099px;
    word-wrap: break-word;
    border: 1px solid black;
    background-color: white;
  }

  .simple-table thead > tr > th {
    border: 1px solid black;
    background-color: white;
    padding: 1px;
    font-size: 13px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    word-wrap: break-word;
  }

  .simple-table tbody > tr > td {
    border: 1px solid black;
    background-color: white;
    padding: 1px;
    font-size: 13px;
    word-wrap: break-word;
  }

  .simple-table tbody > tr:nth-child(even) {
    background-color: white; /* Set even rows to white */
  }

  .simple-table tbody > tr:nth-child(odd) {
    background-color: white; /* Set odd rows to white */
  }

  .simple-table tbody > tr {
    border: 1px solid black;
    background-color: white;
    word-wrap: break-word;
  }

  .bold-last-row td {
    font-weight: bold;
  }

  